import React from "react";
 
const Home = () => {
    return (
        <div>
            <h1>Welcome to GeeksforGeeks</h1>
        </div>
    );
};
 
export default Home;